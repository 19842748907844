// import "../public-dynamic/stylesheets/$shortcuts.css";
import "../public-dynamic/stylesheets/_dev.css";
import "../public-dynamic/stylesheets/app.css";
import "../public-dynamic/stylesheets/form.css";

import { Router } from ":shared/components/route";
import { ToastGroup } from "./components/alert";
import { Modal } from "./components/popup";
import { ConfirmDialog } from "./components/popup";
import { Meta } from "./contexts/head-tag";
import { AppFlavor } from "./contexts/whitelabel";
// routes bases
import { Landing, Auth, Participant, Evaluator, Judge, Auditor, Support } from "./routes";

export function EntryClient() {
  return (
    <Meta title="Designathon">
      <AppFlavor title="Designathon">
        <ToastGroup />
        <Modal />
        <ConfirmDialog />
        <Router>
          <Landing />
          <Auth />
          <Participant />
          <Evaluator />
          <Judge />
          <Auditor />
          <Support />
        </Router>
      </AppFlavor>
    </Meta>
  );
}
